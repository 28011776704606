import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';

import NotFoundComponent from '../components/layout/not-found'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundComponent />
  </Layout>
);

export default NotFoundPage;
